export const conversations = [
  {//Eleven labs Adam, Daniel and Rachel
    description: "You are calling a travel agency to book a flight to Montreal, Canada as a visitor between Mar 15 and 31.<br/>You will be departing from New York city.<br/>You travel alone.",
    pairs: [
      {
        application: {
          en: "Hello, how can I help you?",
          fr: "Bonjour, comment puis-je vous aider ?",
          audio: "/Audio/Bonjour comment puis.mp3"
        },
        hint: "a flight to Montreal, Canada",
        suggestResponses: [
          {
            en: "I want to book a flight to Montreal, Canada.",
            fr: "Je veux réserver un vol pour Montréal, Canada.",
            audio: "/Audio/Je veux reserver.mp3",
          },
          {
            en: "I want to book a flight to Montreal, Canada.",
            fr: "Je souhaite réserver un vol pour Montréal au Canada.",
            audio: "/Audio/Je souhaite reserver.mp3",
          },
          {
            en: "Can you help me book a flight to Montreal, Canada?",
            fr: "Pouvez-vous m'aider à réserver un vol pour Montréal, Canada ?",
            audio: "/Audio/Pouvez-vous aider.mp3",
          },
            //this is to make answers more flexible. Will need AI for this
          { 
            fr: "Je veux réserver un vol pour Montréal au Canada.",
          },
          { 
            fr: "Je souhaite réserver un vol pour Montréal, Canada.",
          },
          { 
            fr: "Pouvez-vous m'aider à réserver un vol pour Montréal au Canada.",
          },
        ],
      },
      {
        application: {
          en: "Sure, when are you planning to travel?",
          fr: "Bien sûr, quand prévoyez-vous de voyager ?",
          audio: "/Audio/Bien sur quand.mp3",
        },
        hint: "March 15 to 31",
        suggestResponses: [
          {
            en: "From March 15 to 31.",
            fr: "Du quinze au trente-et-un mars.",
            audio: "/Audio/Du 15 au.mp3",
          },
          {
            en: "I will travel from March 15 to 31.",
            fr: "Je vais voyager du quinze au trente-et-un mars.",
            audio: "/Audio/Je vais voyager du 15 au 31 mars.mp3",
          },
          {
            en: "Between March 15 and 31. My dates are flexible.",
            fr: "Entre le quinze au trente-et-un mars. Mes dates sont flexibles.",
            audio: "/Audio/Entre le 15.mp3",
          },
          { 
            fr: "Je voyagerai du 15 au 31 mars.",
          },
          { 
            fr: "Je voyage du 15 au 31 mars.",
          },
          { 
            fr: "Du 15 au 31 mars.",
          },
          { 
            fr: "Je voyagerai du quinze au trente-et-un mars.",
          },
          { 
            fr: "Je vais voyager du 15 au 31 mars.",
          },
          { 
            fr: "Je voyage du quinze au trente-et-un mars.",
          },
          { 
            fr: "Entre le 15 et le 31 mars. Mes dates sont flexibles.",
          },
        ],
      },
      {
          application: {
              en: "Ok. Where do you depart from?",
              fr: "D'accord. D'où vous partez ?",
              audio: "/Audio/Accord ou vous.mp3",
          },
          hint: "from New York city",
          suggestResponses: [
              {
                en: "I'll be departing from New York city.",
                fr: "Je partirai de New York.",
                audio: "/Audio/Je partirai de.mp3",
              },
              {
                en: "I'll be departing from New York city.",
                fr: "Je vais partir de New York.",
                audio: "/Audio/Je vais partir de New York.mp3",
              },
              {
                en: "I'll be departing from New York city.",
                fr: "Je pars de New York.",
                audio: "/Audio/Je pars de New York.mp3",
              },
              {
                fr: "De New York."
              }
          ],
      },
      {
          application: {
              en: "Great. How many passengers will be traveling?",
              fr: "Très bien. Combien de passagers voyageront ?",
              audio: "/Audio/Tres bien combien.mp3",
          },
          hint: "alone",
          suggestResponses: [                
              {
                en: "I am travelling alone.",
                fr: "Je voyage seul.",
                audio: "/Audio/Je voyage seul.mp3",
              },
              {
                en: "I am travelling alone.",
                fr: "Je pars seul.",
                audio: "/Audio/Je pars seul.mp3",
              },
              {
                en: "Just me for this trip.",
                fr: "Juste moi pour ce voyage.",
                audio: "/Audio/Juste moi pour.mp3",
              },
              {
                fr: "Juste moi.",
              },
              {
                fr: "Je serai seul.",
              },
          ],
      },
    ],
  },
  {
    description: "You are calling a travel agency to book a flight to Toronto, Canada as a visitor from Jun 22 to July 17.<br/>You will be departing from Mexico.<br/>You travel with your spouse.",
    pairs: [
      {
        application: {
          en: "Hello, how can I help you?",
          fr: "Bonjour, comment puis-je vous aider ?",
          audio: "/Audio/Bonjour comment puis.mp3"
        },
        hint: "a flight to Toronto, Canada",
        suggestResponses: [
          {
            en: "I want to book a flight to Toronto, Canada.",
            fr: "Je veux réserver un vol pour Toronto, Canada.",
            audio: "/Audio/Je veux réserver un vol pour Toronto Canada.mp3",
          },
          {
            en: "I want to book a flight to Toronto, Canada.",
            fr: "Je souhaite réserver un vol pour Toronto au Canada.",
            audio: "/Audio/Je souhaite réserver un vol pour Toronto au Canada.mp3",
          },
          {
            en: "Can you help me book a flight to Toronto, Canada?",
            fr: "Pouvez-vous m'aider à réserver un vol pour Toronto, Canada ?",
            audio: "/Audio/Pouvez-vous m'aider à réserver un vol pour Toronto Canada.mp3",
          },
          { 
            fr: "Je veux réserver un vol pour Toronto au Canada.",
          },
          { 
            fr: "Je souhaite réserver un vol pour Toronto, Canada.",
          },
          { 
            fr: "Pouvez-vous m'aider à réserver un vol pour Toronto au Canada.",
          },
        ],
      },
      {
        application: {
          en: "Sure, when are you planning to travel?",
          fr: "Bien sûr, quand prévoyez-vous de voyager ?",
          audio: "/Audio/Bien sur quand.mp3",
        },
        hint: "Jun 22 to July 17",
        suggestResponses: [
          {
            en: "From Jun 22 to July 17.",
            fr: "Du vingt-deux juin au dix-sept juillet.",
            audio: "/Audio/Du 22 juin au 17 juillet.mp3",
          },
          {
            en: "I will travel from Jun 22 to July 17.",
            fr: "Je vais voyager du vingt-deux juin au dix-sept juillet.",
            audio: "/Audio/Je vais voyager du 22 juin au 17 juillet.mp3",
          },
          {
            en: "Between Jun 22 and July 17. My dates are flexible.",
            fr: "Entre le vingt-deux juin au dix-sept juillet. Mes dates sont flexibles.",
            audio: "/Audio/Entre le 22 juin et le 17 juillet. Mes dates sont flexibles.mp3",
          },
          { 
            fr: "Je voyagerai du 22 juin au 17 juillet.",
          },
          { 
            fr: "Je voyage du 22 juin au 17 juillet.",
          },
          { 
            fr: "Du 22 juin au 17 juillet.",
          },
          { 
            fr: "Je voyagerai du vingt-deux juin au dix-sept juillet.",
          },
          { 
            fr: "Je vais voyager du 22 juin au 17 juillet.",
          },
          { 
            fr: "Je voyage du vingt-deux juin au dix-sept juillet.",
          },
          { 
            fr: "Entre le 22 juin et le 17 juillet. Mes dates sont flexibles.",
          },
        ],
      },
      {
          application: {
              en: "Ok. Where do you depart from?",
              fr: "D'accord. D'où vous partez ?",
              audio: "/Audio/Accord ou vous.mp3",
          },
          hint: "from Mexico",
          suggestResponses: [
              {
                en: "I'll be departing from Mexico.",
                fr: "Je partirai du Mexique.",
                audio: "/Audio/Je partirai du Mexique.mp3",
              },
              {
                en: "I'll be departing from Mexico.",
                fr: "Je vais partir du Mexique.",
                audio: "/Audio/Je vais partir du Mexique.mp3",
              },
              {
                en: "I'll be departing from Mexico.",
                fr: "Je pars du Mexique.",
                audio: "/Audio/Je pars du Mexique.mp3",
              },
              {
                fr: "Du Mexique."
              }
          ],
      },
      {
          application: {
              en: "Great. How many passengers will be traveling?",
              fr: "Très bien. Combien de passagers voyageront ?",
              audio: "/Audio/Tres bien combien.mp3",
          },
          hint: "with my spouse",
          suggestResponses: [                
              {
                en: "I am travelling with my spouse.",
                fr: "Je voyage avec mon conjoint.",
                audio: "/Audio/Je voyage avec mon conjoint.mp3",
              },
              {
                en: "I am travelling with my spouse.",
                fr: "Je pars avec ma conjointe.",
                audio: "/Audio/Je pars avec mon conjoint.mp3",
              },
              {
                en: "There are two.",
                fr: "Il y en a deux.",
                audio: "/Audio/Il y en a deux.mp3",
              },
              {
                fr: "Je voyage avec ma conjointe.",
              },
              {
                fr: "Je pars avec mon conjoint.",
              },
              {
                fr: "Il y a deux voyageurs.",
              },
              {
                fr: "Il y a deux passagers.",
              },
          ],
      },
    ],
  },
  {
    description: "You are calling a travel agency to book a flight to Vancouver, Canada as a visitor from May 12 to Aug 16.<br/>You will be departing from China.<br/>You travel with your daughter.",
    pairs: [
      {
        application: {
          en: "Hello, how can I help you?",
          fr: "Bonjour, comment puis-je vous aider ?",
          audio: "/Audio/Bonjour comment puis.mp3"
        },
        hint: "a flight to Vancouver, Canada",
        suggestResponses: [
          {
            en: "I want to book a flight to Vancouver, Canada.",
            fr: "Je veux réserver un vol pour Vancouver, Canada.",
            audio: "/Audio/Je veux réserver un vol pour Vancouver Canada.mp3",
          },
          {
            en: "I want to book a flight to Vancouver, Canada.",
            fr: "Je souhaite réserver un vol pour Vancouver au Canada.",
            audio: "/Audio/Je souhaite réserver un vol pour Vancouver au Canada.mp3",
          },
          {
            en: "Can you help me book a flight to Vancouver, Canada?",
            fr: "Pouvez-vous m'aider à réserver un vol pour Vancouver, Canada ?",
            audio: "/Audio/Pouvez-vous m'aider à réserver un vol pour Vancouver Canada.mp3",
          },
          { 
            fr: "Je veux réserver un vol pour Vancouver au Canada.",
          },
          { 
            fr: "Je souhaite réserver un vol pour Vancouver, Canada.",
          },
          { 
            fr: "Pouvez-vous m'aider à réserver un vol pour Vancouver au Canada.",
          },
        ],
      },
      {
        application: {
          en: "Sure, when are you planning to travel?",
          fr: "Bien sûr, quand prévoyez-vous de voyager ?",
          audio: "/Audio/Bien sur quand.mp3",
        },
        hint: "May 12 to Aug 16",
        suggestResponses: [
          {
            en: "From May 12 to Aug 16.",
            fr: "Du douze mai au seize août.",
            audio: "/Audio/Du 12 mai au 16 août.mp3",
          },
          {
            en: "I will travel from May 12 to Aug 16.",
            fr: "Je vais voyager du douze mai au seize août.",
            audio: "/Audio/Je vais voyager du 12 mai au 16 août.mp3",
          },
          {
            en: "Between May 12 and Aug 16. My dates are flexible.",
            fr: "Entre le douze mai au seize août. Mes dates sont flexibles.",
            audio: "/Audio/Entre le 12 mai et le 16 août. Mes dates sont flexibles.mp3",
          },
          { 
            fr: "Je voyagerai du 12 mai au 16 août.",
          },
          { 
            fr: "Je voyage du 12 mai au 16 août.",
          },
          { 
            fr: "Du 12 mai au 16 août.",
          },
          { 
            fr: "Je voyagerai du douze mai au seize août.",
          },
          { 
            fr: "Je vais voyager du 12 mai au 16 août.",
          },
          { 
            fr: "Je voyage du douze mai au seize août.",
          },
          { 
            fr: "Entre le 12 mai et le 16 août. Mes dates sont flexibles.",
          },
        ],
      },
      {
          application: {
              en: "Ok. Where do you depart from?",
              fr: "D'accord. D'où vous partez ?",
              audio: "/Audio/Accord ou vous.mp3",
          },
          hint: "from China",
          suggestResponses: [
              {
                en: "I'll be departing from China.",
                fr: "Je partirai de Chine.",
                audio: "/Audio/Je partirai de Chine.mp3",
              },
              {
                en: "I'll be departing from China.",
                fr: "Je vais partir de Chine.",
                audio: "/Audio/Je vais partir de Chine.mp3",
              },
              {
                en: "I'll be departing from China.",
                fr: "Je pars de Chine.",
                audio: "/Audio/Je pars de Chine.m4a",
              },
              {
                fr: "De Chine."
              }
          ],
      },
      {
          application: {
              en: "Great. How many passengers will be traveling?",
              fr: "Très bien. Combien de passagers voyageront ?",
              audio: "/Audio/Tres bien combien.mp3",
          },
          hint: "with my daughter",
          suggestResponses: [                
              {
                en: "I am travelling with my daughter.",
                fr: "Je voyage avec ma fille.",
                audio: "/Audio/Je voyage avec ma fille.m4a",
              },
              {
                en: "I am travelling with my daughter.",
                fr: "Je pars avec ma fille.",
                audio: "/Audio/Je pars avec ma fille.m4a",
              },
              {
                en: "There are two.",
                fr: "Il y en a deux.",
                audio: "/Audio/Il y en a deux.mp3",
              },
              {
                fr: "Il y a deux voyageurs.",
              },
              {
                fr: "Il y a deux passagers.",
              },
          ],
      },
    ],
  },
];

