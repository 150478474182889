import { vocabularies } from './Vocabularies';

export const sentencesForSpeaking = [  
  //TODO
  //TECHNICAL: pass in vocab in the text. 
  //if cannot find it in vocabulary table, it is likely a conjugation. We will search in all column for tenses. And paste content of that table under "wordType" column
  {
    fr: 'Je veux réserver un vol pour Montréal, Canada.',
    vocabulary: [vocabularies['réserver'], vocabularies['vol']],
    audio: "/Audio/Je veux reserver.mp3",
    en: 'I want to book a flight to Montreal, Canada.'
  },
  {
    fr: 'Je souhaite réserver un vol pour Montréal au Canada.',
    vocabulary: [vocabularies['souhaiter']],
    audio: "/Audio/Je souhaite reserver.mp3",
    en: 'I want to book a flight to Montreal, Canada.'
  },
  {
    fr: 'Pouvez-vous m\'aider à réserver un vol pour Montréal, Canada ?',
    vocabulary: [vocabularies['pouvoir'], vocabularies['aider']],
    audio: "/Audio/Pouvez-vous aider.mp3",
    en: 'Can you help me book a flight to Montreal, Canada?'
  },
  {
    en: "From March 15 to 31.",
    fr: "Du quinze au trente-et-un mars.",
    audio: "/Audio/Du 15 au.mp3",
    vocabulary: [vocabularies['mars']],
  },
  {
    en: "Between March 15 and 31. My dates are flexible.",
    fr: "Entre le quinze et le trente-et-un mars. Mes dates sont flexibles.",
    audio: "/Audio/Entre le 15.mp3",
    vocabulary: [vocabularies['date'], vocabularies['flexible']],
  },
  {
    en: "I'll be departing from New York city.",
    fr: "Je partirai de New York.",
    audio: "/Audio/Je partirai de.mp3",
    vocabulary: [vocabularies['partirai']],
  },
  {
    en: "Just me for this trip.",
    fr: "Juste moi pour ce voyage.",
    audio: "/Audio/Juste moi pour.mp3",
    vocabulary: [vocabularies['voyage']],
  },
  {
    en: "I am travelling alone.",
    fr: "Je voyage seul.",
    audio: "/Audio/Je voyage seul.mp3",
    vocabulary: [vocabularies['seul']],
  },
  ];
  
export const sentencesForListening = [
  {
    fr: 'Bien sûr, quand prévoyez-vous de voyager ?',
    vocabulary: [vocabularies['prévoir'], vocabularies['voyager']],
    audio: "/Audio/Bien sur quand.mp3",
    en: 'Sure, when are you planning to travel?'
  },
  {
    fr: 'J\'ai compris. Pouvez-vous m\'indiquer votre ville de départ ?',
    vocabulary: [vocabularies['comprendre'], vocabularies['indiquer'], vocabularies['ville'], vocabularies['départ']],
    audio: "/Audio/Jai compris Pouvez.mp3",
    en: 'Got it. Can you tell me your departure city?'
  },
  {
    en: "Ok. Where do you depart from?",
    fr: "D'accord. D'où vous partez ?",
    audio: "/Audio/Accord ou vous.mp3",
    vocabulary: [vocabularies['partez']],
  },
  {
    en: "Great. How many passengers will be traveling?",
    fr: "Très bien. Combien de passagers voyageront ?",
    audio: "/Audio/Tres bien combien.mp3",
    vocabulary: [vocabularies['passager'], vocabularies['voyager']],
  },
];

export const sentencesForQuebec = [
  {
    qc: 'Allô, est-ce que je peux vous aider ?',
    fr: 'Bonjour, comment puis-je vous aider ?',    
    en: 'Hello, how can I help you?',
    explanation: 'Allô = Bonjour',
    QCaudio: "/Audio/Allo est-ce que.mp3",
    audio: "/Audio/Bonjour comment puis.mp3"
  },
  {
    qc: 'Je veux booker un vol pour Montréal, Canada.',
    fr: 'Je veux réserver un vol pour Montréal, Canada.',    
    en: 'I want to book a flight to Montreal, Canada.',
    explanation: 'booker = réserver',
    QCaudio: "/Audio/Je veux booker.mp3",
    audio: "/Audio/Je veux reserver.mp3"
  },
  {
    en: "Just me for this trip.",
    fr: "Juste moi pour ce voyage.",
    audio: "/Audio/Juste moi pour.mp3",
    qc: 'Juste moi pour ce trip.',    
    explanation: 'trip = voyage',
    QCaudio: "/Audio/Juste moi pour ce trip.mp3",
  },
];