import React, { useState, useEffect, useRef } from 'react';

const HelpComponent = ({ steps, direction }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const handleHelpClick = () => {
    setCurrentStep(0);
    setShowPopup(true);
  };

  const handleCloseClick = () => {
    setShowPopup(false);
  };

  const handleNextClick = () => {
    if (currentStep < steps.length - 1) {
      if (steps[currentStep].ref.current) {
        steps[currentStep].ref.current.classList.remove('scale-up', 'highlight');
      }
      setCurrentStep(currentStep + 1);
    } else {
      setShowPopup(false);
    }
  };

  const handlePrevClick = () => {
    if (currentStep > 0) {
      if (steps[currentStep].ref.current) {
        steps[currentStep].ref.current.classList.remove('scale-up', 'highlight');
      }
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    if (showPopup) {
      if (steps[currentStep]?.ref.current) {
        steps[currentStep].ref.current.classList.add('scale-up', 'highlight');
        steps[currentStep].ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setShowPopup(false);
        }
      };

      const handleResize = () => {
        setShowPopup(false);
      };

      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('resize', handleResize);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      if (steps[currentStep]?.ref.current) {
        steps[currentStep].ref.current.classList.remove('scale-up', 'highlight');
      }
    }
  }, [showPopup, currentStep, steps]);

  return (
    <div>
      <button onClick={handleHelpClick} className="flag-icon  ">
        <img src="Icons/help.svg" alt="Flag" />
      </button>
      {showPopup && (
        <div ref={popupRef} className="popup-container" style={getPopupPosition(steps[currentStep].ref, direction)}>
          <div className="popup-container-layout">
          <div style={{display: 'flex'}}>          
          <span style={{maxWidth: '87%'}}><b>{steps[currentStep].label}</b>{steps[currentStep].text.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>
          <button className="popup-close-button" onClick={handleCloseClick}>×</button>
          </div>
          <div className="popup-button-group">
            <button onClick={handlePrevClick} disabled={currentStep === 0} id="prevButton" className="popup-button">&lt;</button>
            <button onClick={handleNextClick} id="nextButton" className="popup-button">&gt;</button>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getPopupPosition = (ref, direction) => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect(); 
    let top = rect.bottom + 10 ;   
    if(direction === 'top'){
      top = rect.top ;
    }     
    let left = rect.left + 5;

    // Adjust if popup overflows right
    if (left + 200 > window.innerWidth) {
      left = window.innerWidth - 220;
    }

    // Adjust if popup overflows bottom
    if (top + 100 > window.innerHeight) {
      top = window.innerHeight - 210;
    }

    // Ensure the popup doesn't overflow out of the left side
    if (left < 10) {
      left = 10;
    }

    // Ensure the popup doesn't overflow out of the top side
    if (top < 10) {
      top = 10;
    }
    
    return {
      top: `${top}px`,
      left: `${left}px`,
    };
  }
  return { top: '0px', left: '0px' };
 
};

export default HelpComponent;
