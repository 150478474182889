import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import './ThemeSelection.css'; 
import {scenarios} from './ScenarioSelection';

const themes = [
  { name: 'Travel', imgSrc: 'Themes/Travel.png' },
  { name: 'Accommodation', imgSrc: 'Themes/Accommodation.png' },
  { name: 'Shopping', imgSrc: 'Themes/Shopping.png' },
  { name: 'Food', imgSrc: 'Themes/Food.png' }
];

const ThemeSelection = () => {
  let navigate = useNavigate();

  const handleThemeClick = (themeName) => {
    if (themeName === 'Travel') {
      navigate('/scenario-selection'); // This will navigate to the ScenarioSelection page
    }else {
      console.log(`${themeName} clicked`);
      // You can add more navigation logic for other themes here if necessary
    }
  };
  
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const searchRef = useRef(null);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const results = new Set(); // Use Set to avoid duplicates
      Object.keys(scenarios).forEach((category) => {
        if (category.toLowerCase().includes(term)) {
          scenarios[category].forEach((scenario) => results.add(scenario));
        } else {
          scenarios[category].forEach((scenario) => {
            if (scenario.toLowerCase().includes(term)) {
              scenarios[category].forEach((s) => results.add(s));
            }
          });
        }
      });
      setFilteredScenarios(Array.from(results));
    } else {
      setFilteredScenarios([]);
    }
  };

  const handleButtonClick = (scenario) => {
    if (scenario === 'Booking a Flight') {
      navigate('/speaking-sentences');
    }
  };

  const handleSearchIconClick = () => {
    setShowSearchInput(true);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSearchInput(false);
      setSearchTerm('');
      setFilteredScenarios([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="theme-selection-container">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button">Back</button>
        <h3>Choose a Theme</h3>
        <div className="scenario-search" ref={searchRef}>
        {!showSearchInput ? (
          <button onClick={handleSearchIconClick} className="search-icon-button">
            <img src="Icons/search.svg" alt="Search" />
          </button>
        ) : (
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search scenarios..."
            className="search-input"
          />
        )}
          {filteredScenarios.length > 0 && (
            <div className="search-results">
              {filteredScenarios.map((scenario, index) => (
                <button
                  key={index}
                  className={`search-result-button ${scenario !== 'Booking a Flight' ? 'inactive' : ''}`}
                  onClick={() => handleButtonClick(scenario)}
                >
                  {scenario}
                  {scenario !== 'Booking a Flight' && <span className="lock-icon">&#128274;</span>}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>   
      <div className="themes-grid">      
        {themes.map((theme, index) => (
          <div key={index} className="theme-card" onClick={() => handleThemeClick(theme.name)}>            
            <img 
              src={theme.imgSrc} 
              alt={theme.name} 
              className={`theme-image ${theme.name !== 'Travel' ? 'inactive' : ''}`} 
            />           
            <button className={`theme-button ${theme.name !== 'Travel' ? 'inactive' : ''}`}>
              {theme.name}
              {theme.name !== 'Travel' && <span className="lock-icon">&#128274;</span>}
            </button>            
          </div>
        ))} 
      </div>    
      <div className="icons-container">       
        <div className="icon home-icon" onClick={() => navigate('/')}><img src="Icons/home.svg" alt="Home" /></div>
        <div className="icon study-icon" onClick={() => navigate('/study')}><img src="Icons/study.svg" alt="Study" /></div>
        <div className="icon person-icon" onClick={() => navigate('/profile')}><img src="Icons/account-settings.svg" alt="Listen" /></div>
      </div>
    </div>
  );
};

export default ThemeSelection;
