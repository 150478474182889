import React, { createContext, useState, useContext } from 'react';
//TODO we will store users detected answers by conversations, % match for each sentence, average % score current and at the end of month for last 12 months. 
// In Achievement, we will show # finished conversations, average score %, average score %  last 12 months.

// Create the context
const UserSpeakingAnswersContext = createContext({
    conversationIndex: 0,
    updateConversationIndex: () => {},
    userSpeakAnswers: [{ pairs: [] }],
    updateMatchPercentage: () => {}, 
    updateMatchSuggestResponse: () => {},
  });

// Hook to use the context
export const useUserSpeakingAnswers = () => useContext(UserSpeakingAnswersContext);

// Context provider component
export const UserSpeakingAnswersProvider = ({ children }) => {
    const [theme, setTheme] = useState(null);
    const [topic, setTopic] = useState(null);
    const [scenario, setScenario] = useState(null);
    const [conversationIndex, setConversationIndex] = useState(0); 

    const [userSpeakAnswers, setUserSpeakAnswers] = useState([
    {
      pairs: [
        {
          userAnswer: '',
          matchPercentage: 0,
          matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
      ],
    },
    {
      pairs: [
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
      ],
    },
    {
      pairs: [
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
        { userAnswer: '', matchPercentage: 0, matchSuggestResponse: '' },
      ],
    },
    // More conversations responses can be added here
    ]);
    
    const updateConversationIndex = (index) => {
        setConversationIndex(index);
    }
  
    // Function to update userAnswer
    const updateUserAnswer = (conversationIndex, pairIndex, newUserAnswer) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                userAnswer: newUserAnswer,
            };
            return newUserAnswers;
        });
    };

    // Function to update matchPercentage
    const updateMatchPercentage = (conversationIndex, pairIndex, newMatchPercentage) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchPercentage: newMatchPercentage,
            };
            return newUserAnswers;
        });
    };

    // Function to update matchSuggestResponse
    const updateMatchSuggestResponse = (conversationIndex, pairIndex, newMatchSuggestResponse) => {
        setUserSpeakAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchSuggestResponse: newMatchSuggestResponse,
            };
            return newUserAnswers;
        });
    };

    return (
        <UserSpeakingAnswersContext.Provider value={{
            conversationIndex,
            updateConversationIndex,
            userSpeakAnswers,
            updateUserAnswer,
            updateMatchPercentage,
            updateMatchSuggestResponse,
            theme,
            topic,
            scenario,
            setTheme,
            setTopic,
            setScenario
        }}>
            {children}
        </UserSpeakingAnswersContext.Provider>
    );
};
  
  
   // Function to update a specific pair's properties
   //to use: updateUserAnswer(0, 1, {
        //     userAnswer: 'Updated answer',
        //     matchPercentage: 75,
        //     matchSuggestResponse: 'New suggested response',
        // }
//    const updateUserAnswerRecord = (conversationIndex, pairIndex, data) => {
//     setUserAnswers(prevUserAnswers => {
//         const newUserAnswers = [...prevUserAnswers];
//         newUserAnswers[conversationIndex].pairs[pairIndex] = {
//             ...newUserAnswers[conversationIndex].pairs[pairIndex],
//             ...data, 
//         };
//         return newUserAnswers;
//     });