import React, { useState } from 'react';

const FlagComponent = () => {
  const [isFlagClicked, setIsFlagClicked] = useState(false);
  const flagOptions = ['AIducare does not recognize my voice', 'There is a grammar or spelling error', 'Other issues'];

  const handleIconFlagClose = () => {
    setIsFlagClicked(false);
  };

  const handleIconFlagOptionClick = (option) => {
    console.log(option); // You might want to do something with the option
    handleIconFlagClose();
  };

  return (
    <>
      <button className="flag-icon" onClick={() => setIsFlagClicked(true)}>
        <img src="Icons/flag.svg" alt="Flag" />
      </button>
      {isFlagClicked && (
        <div className="popup-overlay">
          <div className="popup">
            <p><b>Please select a problem that you are experiencing</b></p>
            <ul>
              {flagOptions.map((option, index) => (
                <li className="inactive" key={index} onClick={() => handleIconFlagOptionClick(option)}>
                  {option} <span className="lock-icon">&#128274;</span>
                </li>
              ))}
            </ul>
            <button className="close-button" onClick={handleIconFlagClose}>Close</button>
          </div>
        </div>
      )}
    </>
  );
};

export default FlagComponent;
