import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSpeakingAnswers } from '../Practice/UserSpeakingAnswers';
import { highlightMatches, calculateAverageMatch} from '../Reusables/Utilities';
import { showSweetAlert } from '../Reusables/Utilities';
import Swal from 'sweetalert2';

//TODO: currently, it writes temporary in UsersAnswers.js

const PronunciationReport = () => {
    let navigate = useNavigate();
    const {conversationIndex, userSpeakAnswers } = useUserSpeakingAnswers();
    const conversation = userSpeakAnswers[conversationIndex]; 
    return (
        <div className="pronunciation-report">
            <div className="top-row">
                <button onClick={() => navigate(-1)} className="back-button">Back</button>
                <h3>You Score {calculateAverageMatch(conversation).toFixed(0)}%</h3>
            </div>            
            <ul>
                {conversation.pairs.map((pair, pairIndex) => (
                    <div className="result-box">   
                        <div className="line-1">                    
                            <span className="label">Detected:</span>
                            <span className="content">{highlightMatches(pair.matchSuggestResponse, pair.userAnswer)}</span>
                        </div>
                        <div className="line-2">  
                            <span className="label">Expected:</span>
                            <span className="content">{pair.matchSuggestResponse}</span>                   
                        </div>
                    </div>  
                ))}
            </ul>
            <div className='next-button-alone-container'>
                <button className="next-button-alone" onClick={() => navigate('/completion-page')}>Next</button>       
            </div>                     
        </div>
    );
};

export default PronunciationReport;