import React, { useState, useEffect, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sentencesForSpeaking } from './Sentences'; 
import { useFavorites } from '../MainPages/FavoriteVocab';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { highlightMatches } from '../Reusables/Utilities';
import { useRecordUser } from '../Reusables/RecordUser';
import FlagComponent from '../Reusables/FlagComponent';
import HelpComponent from '../Reusables/HelpComponent';
import MenuComponent from '../Reusables/MenuComponent';
import './Learn.css';

const SpeakingSentences = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  const { index, fromSummary } = location.state || { index: 0 , fromSummary: false}; 
  const [cameFromSummary, setCameFromSummary] = useState(fromSummary);  // handling go to next sentence in speaking
  const [current, setCurrent] = useState(index);
  const currentVocabulary = sentencesForSpeaking[current]?.vocabulary || [];
  const { favorites, toggleFavorite } = useFavorites();
  const {isPlaying, isPlayingSlower, playPause, stopAudio, play, slowerPause, currentAudioFile} = usePlayAudio(); 
  const {transcription, recording, startRecording, stopRecording, shouldSendToServerRef, nagivateAway} = useRecordUser();
  const originalSentence = sentencesForSpeaking[current]?.fr;
  const [formatted, setFormatted] = useState(false); 
  const [formattedSentence, setFormattedSentence] = useState('');
  const previousTranscriptionRef = useRef(transcription);
 
  useEffect(() => {
    // Update the current state if the index changes due to navigation
    setCurrent(index);
  }, [index]);

  const goBack = () => {
    if (fromSummary && cameFromSummary) {
      setFormatted(false);
      setFormattedSentence('');
      stopAudio();
      nagivateAway();
      navigate('/summary-page'); 
    } else if (current === 0) {
      setFormatted(false);
      setFormattedSentence('');
      stopAudio();
      nagivateAway();
      navigate('/scenario-selection');      
    } else {
      setFormatted(false);
      setFormattedSentence('');
      setCurrent(current - 1);          // remains in speaking-selection page    
      stopAudio();
      stopRecording();
      showTranslation = false;          //reset translation button
      shouldSendToServerRef.current = false;
    }
  };

  const goToNext = () => {
    if (current < sentencesForSpeaking.length - 1) {
      setFormatted(false);
      setFormattedSentence('');
      setCameFromSummary(false);
      setCurrent(current + 1);          // remains in speaking-selection page
      stopAudio();
      stopRecording();
      showTranslation = false;
      translate('');
      shouldSendToServerRef.current = false;
    } else {
      setFormatted(false);
      setFormattedSentence('');
      stopAudio();
      nagivateAway();
      navigate('/listening-sentences'); // Navigate to Listening Page
    }
  };

  const goToSummary = () => {
    setFormatted(false);
    setFormattedSentence('');
    stopAudio();
    nagivateAway();
    navigate('/summary-page', { state: { sentenceIndex: current, fromSpeaking : true }});    
  }

  /********** TRANSLATION **********/ 
  let showTranslation = false;
  const translate = (translationText) => {
    showTranslation = !showTranslation;
    showTranslation ? document.getElementById('translation').innerText = translationText : document.getElementById('translation').innerText = '';
  }  
   
  /********** HIGHLIGHT SENTENCE **********/
  useEffect(() => {     
    if (transcription !== previousTranscriptionRef.current) {
      previousTranscriptionRef.current = transcription;
      setFormattedSentence(highlightMatches(transcription, originalSentence));
      setFormatted(true);
      console.log('detected sentence = ' + transcription);
    }
  }, [transcription]); 
  
  /********** POPUP INSTRUCTION **********/
  const microphoneRef = useRef(null);
  const slowRef = useRef(null);
  const translationRef = useRef(null);
  const flagRef = useRef(null);  
  const favoriteRef = useRef(null);
  const menuRef = useRef(null);
  const summaryRef = useRef(null);

  const steps = [
    { ref: microphoneRef, label: "Microphone: ", text: "Read this sentence to receive instant pronunciation feedback.<br/>If this button does not work, go to your browser Settings -> Microphone -> Ask" },
    { ref: slowRef, label: "Slow: ", text: "Adjust audio speed" },
    { ref: translationRef, label: "Translation: ", text: "See translation" },
    { ref: flagRef, label: "Flag: ", text: "Flag a problem" },
    { ref: favoriteRef, label: "Heart: ", text: "Add this word to your vocabulary list" },
    { ref: menuRef, label: "Menu list: ", text: "To access your vocabulary list: go to 'Study' -> 'My Vocabulary'" },    
    { ref: summaryRef, label: "Summary: ", text: "See a summary of all sentences" }
  ];

  /********** INTERFACE **********/
  return (
    <div className="sentence-practice">
      <div className="top-row">
        <button onClick={goBack} className="back-button">Back</button>
        <h4>Record yourself reading this sentence</h4>
        <div className="top-icons-container">      
          <div ref={menuRef}><MenuComponent menuType="type1"/></div>
          <div ref={flagRef}><FlagComponent /></div>
        </div>    
      </div>   
      <HelpComponent steps={steps}/> 
      <div className="practice-body"> 
        <div className="sentence-with-controls">
          <span className="french-sentence">
          <b>{formatted ? formattedSentence : originalSentence}</b>
            {/*
            {detectedSentence !== '' && 'Detected: ' + detectedSentence.replace(/["]/g, '')} */}
          </span>
          <div className="controls">
            <button  className="listen-button" onClick={() => playPause(sentencesForSpeaking[current]?.audio)}>
              <img src={isPlaying ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
            </button>
            <button ref={slowRef} className="slowdown-button" onClick={() => slowerPause(sentencesForSpeaking[current]?.audio)}>
              <img src={isPlayingSlower ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
            </button>
            {isPlayingSlower && currentAudioFile === sentencesForSpeaking[current]?.audio && <PlaybackRateControl />}
            <button ref={translationRef} className="translation-button" onClick={() => translate(sentencesForSpeaking[current]?.en)}>
              <img src="Icons/translation.svg" alt="Translation" />
            </button>
          </div>
        </div>
        <div id="translation" style={{paddingLeft:'30px'}}></div>   
            
        <div className="microphone" onClick={recording ? stopRecording : startRecording}> 
          {recording ? (
            <button style={{marginTop: '10px', cursor: 'pointer'}} className="recording-animation">
              <img src="Icons/stop.svg" alt="Stop" />          
            </button>
          )
          :
            <button ref={microphoneRef} className="microphone-button">
            <img src="Icons/microphone.svg" alt="Microphone" />
            </button>
          }
        </div>

        <div className="vocabulary-list">
          {currentVocabulary.map((vocab, index) => (
            <div>
              <div key={index} className="vocab-item">             
                <span className="french-word">{vocab.french}</span>
                <span className="ipa">{vocab.ipa}</span>
                <button className="audio-button" onClick={() => play(vocab.audio)}>
                  <img src="Icons/listenDictionary.svg" alt="Listen" />
                </button>
                <button  ref={favoriteRef} className="favorite-button" onClick={() => toggleFavorite(vocab.french)}>
                  {favorites.has(vocab.french) ? <img src="Icons/red-heart.svg" alt="FilledHeart" /> : <img src="Icons/heart.svg" alt="UnfilledHeart"/>}
                </button>                  
                <br/>              
                [{vocab.wordType}]
                <br/>              
                {vocab.english}
                <br/>
                {vocab.wordType === 'Verb' && vocab.tense !== '' && (
                  <div className="conjugation">                  
                    <span><b>{vocab.tense}</b></span>
                    <br/>
                    <span>{vocab.tenseConjugation.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="navigation-buttons">
        <button ref={summaryRef} className="summary-button" onClick={goToSummary}>Summary</button>
        <button className="next-button" onClick={goToNext}>Next</button>     
      </div>      
    </div>
  );
};

export default SpeakingSentences;