export const vocabularies = {
    'réserver': { french: 'réserver', ipa: '/ʀezɛʀve/', english: 'to reserve, to book' , audio: "/Audio/fr_reserver.mp3",wordType: 'Verb', tense: '', tenseConjugation: ''},
    'vol': { french: 'vol', ipa: '/vɔl/', english: 'flight'  , audio: "/Audio/fr_vol.mp3", wordType: 'Masculine Noun', tense: '', tenseConjugation: ''},
    'souhaiter': { french: 'souhaiter', ipa: '/swete/', english: 'to wish' , audio: "/Audio/fr_souhaiter.mp3" , wordType: 'Verb', tense: 'Present', tenseConjugation: 'je souhaite<br/>tu souhaites<br/>il/elle souhaite<br/>nous souhaitons<br/>vous souhaitez<br/>ils/elles souhaitent'},
    'pouvoir': { french: 'pouvoir', ipa: '/puvwaʀ/', english: 'can'  , audio: "/Audio/fr_pouvoir.mp3", wordType: 'Verb', tense: 'Present', tenseConjugation: 'je peux<br/>tu peux<br/>il/elle peut<br/>nous pouvons<br/>vous pouvez<br/>ils/elles peuvent'},
    'aider': { french: 'aider', ipa: '/ede/', english: 'to help' , audio: "/Audio/fr_aider.mp3" , wordType: 'Verb', tense: '', tenseConjugation: ''},
    'prévoir': { french: 'prévoir', ipa: '/pʀevwaʀ/', english: 'to plan' , audio: "/Audio/fr_prevoir.mp3" , wordType: 'Verb', tense: 'Present', tenseConjugation: 'je prévois<br/>tu prévois<br/>il/elle prévoit<br/>nous prévoyons<br/>vous prévoyez<br/>ils/elles prévoient'},
    'voyager': { french: 'voyager', ipa: '/vwajaʒe/', english: 'to travel'  , audio: "/Audio/fr_voyager.mp3", wordType: 'Verb', tense: '', tenseConjugation: ''},
    'comprendre': { french: 'comprendre', ipa: '/kɔ̃pʀɑ̃dʀ/', english: 'to understand'  , audio: "/Audio/fr_comprendre.mp3", wordType: 'Verb', tense: 'Present Perfect', tenseConjugation: 'j\'ai compris<br/>tu as compris<br/>    il/elle a compris<br/>    nous avons compris<br/>    vous avez compris<br/>    ils/elles ont compris'},
    'indiquer': { french: 'indiquer', ipa: '/ɛ̃dike/', english: 'to point out'  , audio: "/Audio/fr_indiquer.mp3", wordType: 'Verb', tense: '', tenseConjugation: ''},
    'ville': { french: 'ville', ipa: '/vil/', english: 'city'  , audio: "/Audio/fr_ville.mp3", wordType: 'Feminine Noun'},
    'départ': { french: 'départ', ipa: '/depaʀ/', english: 'departure'  , audio: "/Audio/fr_depart.mp3", wordType: 'Masculine Noun'},
    'date': { french: 'date', ipa: '/dat/', english: 'date' , audio: '/Audio/fr_date.mp3' , wordType: 'Feminine Noun'},
    'mars': { french: 'mars', ipa: '/maʀs/', english: 'March' , audio: '/Audio/fr_mars.mp3' , wordType: 'Masculine Noun'},
    'flexible': { french: 'flexible', ipa: '/flɛksibl/', english: 'flexible' , audio: '/Audio/fr_flexible.mp3' , wordType: 'Adjective'},
    'partirai': { french: 'partir', ipa: '/paʀtiʀ/', english: 'to leave' , audio: '/Audio/fr_partir.mp3' , wordType: 'Verb', tense: 'Future', tenseConjugation: 'je partirai<br/>tu partiras<br/>il/elle partira<br/>nous partirons<br/>vous partirez<br/>ils/elles partiront'}, 
    'voyage': { french: 'voyage', ipa: '/vwajaʒ/', english: 'journey, trip' , audio: '/Audio/fr_voyage.mp3' , wordType: 'Masculine Noun', tense: '', tenseConjugation: ''},
    'seul': { french: 'seul', ipa: '/sœl/', english: 'alone' , audio: '/Audio/fr_seul.mp3' , wordType: 'Adjective or Adverb', tense: '', tenseConjugation: ''},
    'partez': { french: 'partir', ipa: '/paʀtiʀ/', english: 'to leave' , audio: '/Audio/fr_partir.mp3' , wordType: 'Verb', tense: 'Present', tenseConjugation: 'je pars<br/>tu pars<br/>il/elle part<br/>nous partons<br/>vous partez<br/>ils/elles partent'}, 
    'passager': { french: 'passager', ipa: '/pasaʒe/', english: 'passenger' , audio: '/Audio/fr_passager.mp3' , wordType: 'Masculine Noun', tense: '', tenseConjugation: ''}, 
    // '': { french: '', ipa: '//', english: '' , audio: '' , wordType: '', tense: '', tenseConjugation: ''},
  };
  //conjugation, feminine/masculine, adj or noun or verb

