import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSpeakingAnswers } from '../Practice/UserSpeakingAnswers';
import { conversations } from '../Practice/Conversations';

const ReviseConversation = () => {
  let navigate = useNavigate();
  const [pairs, setPairs] = useState(conversations[0].pairs);
  const {theme, topic, scenario} = useUserSpeakingAnswers();

  return (
    <div className="print-page">
      <div>
      <button onClick={() => navigate(-1)} className="back-button">Back</button>
        <br/>
        <ul className="completion-details print-section">
          <li><b>Theme:</b> Travel</li>             
          <li><b>Topic:</b> Flight</li>
          <li><b>Scenario:</b> Flight Booking</li>
        </ul>
        <br/>
        <br/>
        <div className="print-section">
            {pairs.map(pair => (
                <div key={pair.id}>
                    <p><strong>A: </strong>{pair.application.fr}</p>
                    <div className="userSuggestAnswers">
                    {pair.suggestResponses.map((response, idx) => (
                    <p><strong>B{idx+1}: </strong>{response.fr}</p>
                    ))}    
                    </div>          
                    <br/>          
                </div>
            ))}
        </div>
      </div>       
    </div>
  );
};

export default ReviseConversation; 