import React, { createContext, useState, useContext } from 'react';
//TODO we will store users detected answers by conversations, % match for each sentence, average % score current and at the end of month for last 12 months. 
// In Achievement, we will show # finished conversations, average score %, average score %  last 12 months.

// Create the context
const UserWritingAnswersContext = createContext({
    conversationIndex: 0,
    updateConversationIndex: () => {},
    userWriteAnswers: [{ pairs: [] }],
    updateUserAnswer: () => {}, 
    updateMatchPercentage: () => {}, 
    updateMatchSuggestResponse: () => {},
  });

// Hook to use the context
export const useUserWritingAnswers = () => useContext(UserWritingAnswersContext);

// Context provider component
export const UserWritingAnswersProvider = ({ children }) => {
    const [theme, setTheme] = useState(null);
    const [topic, setTopic] = useState(null);
    const [conversationIndex, setConversationIndex] = useState(0);
    const [scenario, setScenario] = useState(null);

    const [userWriteAnswers, setUserWriteAnswers] = useState([
    {
      pairs: [
        {
          userAnswer: '',
          matchPercentage: 0,
          matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
      ],
    },
    {
      pairs: [
        {
          userAnswer: '',
          matchPercentage: 0,
          matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
      ],
    },
    {
      pairs: [
        {
          userAnswer: '',
          matchPercentage: 0,
          matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
        {
            userAnswer: '',
            matchPercentage: 0,
            matchSuggestResponse: '',
        },
      ],
    },
    // More conversations responses can be added here
    ]);

    const updateConversationIndex = (index) => {
        setConversationIndex(index);
    }
  
    // Function to update userAnswer
    const updateUserAnswer = (conversationIndex, pairIndex, newUserAnswer) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                userAnswer: newUserAnswer,
            };
            return newUserAnswers;
        });
    };

    // Function to update matchPercentage
    const updateMatchPercentage = (conversationIndex, pairIndex, newMatchPercentage) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchPercentage: newMatchPercentage,
            };
            return newUserAnswers;
        });
    };

    // Function to update matchSuggestResponse
    const updateMatchSuggestResponse = (conversationIndex, pairIndex, newMatchSuggestResponse) => {
        setUserWriteAnswers(prevUserAnswers => {
            const newUserAnswers = [...prevUserAnswers];
            newUserAnswers[conversationIndex].pairs[pairIndex] = {
                ...newUserAnswers[conversationIndex].pairs[pairIndex],
                matchSuggestResponse: newMatchSuggestResponse,
            };
            return newUserAnswers;
        });
    };

    return (
        <UserWritingAnswersContext.Provider value={{
            conversationIndex,
            updateConversationIndex,
            userWriteAnswers,
            updateUserAnswer,
            updateMatchPercentage,
            updateMatchSuggestResponse,
            theme,
            topic,
            scenario,
            setTheme,
            setTopic,
            setScenario
        }}>
            {children}
        </UserWritingAnswersContext.Provider>
    );
};
  
  