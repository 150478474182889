
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Study.css'; 

const Study = () => {
  let navigate = useNavigate();
  return(
    <div className="study-page">
        <div className="top-row">
          <button onClick={() => navigate(-1)} className="back-button">Back</button>
        </div>
        <div className="study-buttons">
          <button className="study-button" onClick={() => navigate('/my-vocabulary')}>My Vocabulary</button>
          <button className="study-button inactive">Pronunciation{<span className="lock-icon">&#128274;</span>}  </button>
          <button className="study-button inactive">Grammar{<span className="lock-icon">&#128274;</span>}  </button>
        </div>

        <div className="icons-container">       
        <div className="icon home-icon" onClick={() => navigate('/')}><img src="Icons/home.svg" alt="Home" /></div>
        <div className="icon study-icon" onClick={() => navigate('/study')}><img src="Icons/study.svg" alt="Study" /></div>
        <div className="icon person-icon" onClick={() => navigate('/profile')}><img src="Icons/account-settings.svg" alt="Listen" /></div>
        </div>
    </div>
  )
};

export default Study;