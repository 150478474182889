import React from 'react';
import { useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSpeakingAnswers } from '../Practice/UserSpeakingAnswers';
import { showSweetAlert } from '../Reusables/Utilities';
import HelpComponent from '../Reusables/HelpComponent';
import './ScenarioSelection.css'; 

export const scenarios = {
    'Flight': ['Booking a Flight', 'Requesting Baby Bassinet', 'Requesting Special Assistance', 'Transit Flights'],
    'Travel Insurance' : ['Insurance Quote' , 'Insurance Claim'],
    'Airport' : ['Luggage scan', 'Customs Clearance', 'Boarding Gate Change'],
    'Hotel' : ['Hotel booking', 'Hotel check in'],
    'Money Exchange' : ['Money Exchange'],
  };

const ScenarioSelection = () => {
  let navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const { setTheme, setTopic, setScenario } = useUserSpeakingAnswers();
  
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    setSelectedScenario(null); // Ensure the scenario is reset when changing topics
    setTopic(topic);
  };

  const handleScenarioClick = (scenario) => {
    setSelectedScenario(scenario);
    setScenario(scenario);
  };

  const handleActionClick = (action) => {
    if (!selectedScenario) {
      showSweetAlert('Choose a topic', 'choose-scenario');
    } else {
      console.log(`${action} for ${selectedScenario}`);
      if (action === 'STUDY') {
        navigate('/speaking-sentences'); 
      } else if (action === 'PRACTICE') {
        navigate('/practice-speaking', { state: { fromScenario : true }}); 
      }
    }
  };

  useEffect(() => {
    if (selectedTopic && selectedScenario) {
      studyRef.current.classList.add('highlight');
    } else {
      studyRef.current.classList.remove('highlight');
    }
  }, [selectedTopic, selectedScenario]);

  /********** POPUP INSTRUCTION **********/
  const chosenRef = useRef(null);
  const studyRef = useRef(null);
  const praticeRef = useRef(null);

  const steps = [
    { ref: chosenRef, label: "", text: "Selected topic and scenario are shown here" },      
    { ref: studyRef, label: "Study: ", text: "Study for this topic & scenario" },    
    { ref: praticeRef, label: "Practice: ", text: "Skip studying and practice right away" }
  ];

  return (
    <div className="scenario-container">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button">Back</button>
        <h3>Choose a Topic & Scenario</h3>
      </div> 
      <HelpComponent steps={steps} /> 
      <div className='topic-scenarios'>
        {Object.keys(scenarios).map((topic, index) => (
          <React.Fragment key={index}>
            <div  className="topics">
            <button onClick={() => (index === 0) && handleTopicClick(topic)} 
             style={{fontWeight: '600', fontSize: 'clamp(12px, 4vw, 15px)'}}
            className={`topic-button ${selectedTopic === topic ? 'active' : ''} ${index > 0 && "inactive"}`  }>
              {topic} 
              {index === 0 && <span>{selectedTopic === topic ? ' ' : ' ▼'}</span>}
              {index > 0 && <span className="lock-icon">&#128274;</span>}
            </button>
            </div>
            {selectedTopic === topic && scenarios[topic].map((scenario, idx) => (
              <div className="scenarios" key ={scenario}>                
                  <button onClick={() => (idx === 0) && handleScenarioClick(scenario)} 
                  style={{fontWeight: '600', fontSize: 'clamp(11px, 3vw, 15px)'}}
                  className={`scenario-button ${selectedScenario === scenario ? 'active' : ''} ${idx > 0 && "inactive"}`}>                    
                    {scenario} {idx > 0 && <span className="lock-icon">&#128274;</span>}
                  </button>                
              </div>
            ))}
          </React.Fragment>
        ))}
        <br/>
        <span ref={chosenRef} style={{fontSize: 'clamp(11px, 3vw, 15px)'}}><strong>Selected Topic: </strong>{selectedTopic}</span>
        <br/>
        <span style={{fontSize: 'clamp(11px, 3vw, 15px)'}}><strong>Selected Scenario: </strong>{selectedScenario}</span>
      </div>        
      <div className="action-buttons">
        <button onClick={() => handleActionClick('STUDY')} ref={studyRef} className="action-button study"><b>STUDY</b></button>
        <button onClick={() => handleActionClick('PRACTICE')} ref={praticeRef} className="action-button practice"><b>PRACTICE</b></button>
      </div>
     
      <div className="icons-container"> 
        <div className="icon home-icon" onClick={() => navigate('/')}><img src="Icons/home.svg" alt="Home" /></div>
        <div className="icon study-icon" onClick={() => navigate('/study')}><img src="Icons/study.svg" alt="Study" /></div>
        <div className="icon person-icon" onClick={() => navigate('/profile')}><img src="Icons/account-settings.svg" alt="Listen" /></div>
      </div>  
    </div>
  );
};

export default ScenarioSelection;
