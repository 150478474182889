import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sentencesForQuebec, sentencesForListening} from './Sentences'; 
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import FlagComponent from '../Reusables/FlagComponent';
import MenuComponent from '../Reusables/MenuComponent';

const QuebecSentences = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { index, fromSummary } = location.state || { index: 0, fromSummary : false }; 
  const [cameFromSummary, setCameFromSummary] = useState(fromSummary);  // handling go to next sentence in quebec
  const [current, setCurrent] = useState(0);
  const {isPlaying, isPlayingSlower, playPause, stopAudio, slowerPause, currentAudioFile} = usePlayAudio(); 

  useEffect(() => {
    // Update the current state if the index changes due to navigation
    setCurrent(index);
  }, [index]);  

  const goBack = () => {
    if (fromSummary && cameFromSummary) {
      stopAudio();
      navigate('/summary-page'); 
    } else if (current === 0) {
      stopAudio();
      navigate('/listening-sentences' , { state: { index: sentencesForListening.length - 1 }});      
    } else {
      setCurrent(current - 1);
      stopAudio();
    }
  };

  const goToNext = () => {
    if (current < sentencesForQuebec.length - 1) {
      setCameFromSummary(false);
      setCurrent(current + 1);
      stopAudio();
    } else {
      stopAudio();
      navigate('/summary-page'); 
    }
  };

  const goToSummary = () => {
    stopAudio();
    navigate('/summary-page', { state: { sentenceIndex: current, fromSpeaking : true }});    
  }

  return (
    <div className="sentence-practice">
      <div className="top-row">
        <button onClick={goBack} className="back-button">Back</button>
        <h4>Quebec expressions</h4>
        <div className="top-icons-container">       
          <div><MenuComponent menuType="type1"/></div>
          <FlagComponent/>          
        </div> 
      </div>   
      <div className="practice-body"> 
        <div>
            <div className="sentence-with-controls">
                <span className="french-sentence"><b>QC: </b>{sentencesForQuebec[current]?.qc}</span>
                <div className="controls">
                    <button className="listen-button" onClick={() => playPause(sentencesForQuebec[current]?.QCaudio)}>
                      <img src={isPlaying && currentAudioFile === sentencesForQuebec[current]?.QCaudio ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
                    </button>
                    <button className="slowdown-button" onClick={() => slowerPause(sentencesForQuebec[current]?.QCaudio)}>
                      <img src={isPlayingSlower && currentAudioFile === sentencesForQuebec[current]?.QCaudio  ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
                    </button>
                     {isPlayingSlower && currentAudioFile === sentencesForQuebec[current]?.QCaudio && <PlaybackRateControl />}
                </div>            
            </div>
            <div className="sentence-with-controls">
                <span> <b>FR: </b>{sentencesForQuebec[current]?.fr}</span>
                <div className="controls">
                    <button className="listen-button" onClick={() => playPause(sentencesForQuebec[current]?.audio)}>
                      <img src={isPlaying && currentAudioFile === sentencesForQuebec[current]?.audio  ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
                    </button>
                    <button className="slowdown-button" onClick={() => slowerPause(sentencesForQuebec[current]?.audio)}>
                      <img src={isPlayingSlower && currentAudioFile === sentencesForQuebec[current]?.audio  ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
                    </button>
                    {isPlayingSlower && currentAudioFile === sentencesForQuebec[current]?.audio && <PlaybackRateControl />}
                </div>
            </div>      
            <br/>   
            <span style={{paddingLeft:'30px'}} className="explanation">{sentencesForQuebec[current]?.explanation}</span>             
            <br/><br/>
            <span style={{paddingLeft:'30px'}}><b>EN: </b>{sentencesForQuebec[current]?.en}</span>   
        </div>       
      </div>
      <div className="navigation-buttons">
        <button className="summary-button" onClick={goToSummary}>Summary</button>
        <button className="next-button" onClick={goToNext}>Next</button>     
      </div>      
    </div>
  );
};

export default QuebecSentences;