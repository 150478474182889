// MenuComponent.js
import React, { useState, useRef, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecordUser } from './RecordUser';

const MenuComponent = ({ menuType }) => {
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const {ready, nagivateAway} = useRecordUser();

  const toggleMenu = () => {
    setIsMenuClicked(!isMenuClicked);
  };

  const handleNavigation = (path) => {
    setIsMenuClicked(false);
    if (ready){
        nagivateAway();
    }
    navigate(path);    
  };
  
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderMenu = () => {
    switch (menuType) {
      case 'type1':
        return (
          <div className="menu-overlay" ref={menuRef}>
           <div className="menu">
            <button className="menu-button" onClick={() => handleNavigation('/')}>Home</button>
            <button className="menu-button" onClick={() => handleNavigation('/study')}>Study</button>
            <button className="menu-button" onClick={() => handleNavigation('/profile')}>Profile</button>         
          </div>
          </div>
        );
      case 'type2':
        return (
          <div className="menu-overlay" ref={menuRef}>
          <div className="menu">
            <button className="menu-button" onClick={() => handleNavigation('/summary-page')}>Summary</button>
            <button className="menu-button" onClick={() => handleNavigation('/')}>Home</button>
            <button className="menu-button" onClick={() => handleNavigation('/study')}>Study</button>
            <button className="menu-button" onClick={() => handleNavigation('/profile')}>Profile</button>         
          </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <button className="menu-icon" onClick={toggleMenu}>Menu</button>
      {isMenuClicked && renderMenu()}
    </div>
  );
};

export default MenuComponent;
