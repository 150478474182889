import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { conversations } from './Conversations';
import { useUserWritingAnswers  } from './UserWritingAnswers';
import { showSweetAlert, getMatchPercentage } from '../Reusables/Utilities';
import AnswerInput from '../Reusables/AnswerInput';
import FlagComponent from '../Reusables/FlagComponent';
import HelpComponent from '../Reusables/HelpComponent';
import MenuComponent from '../Reusables/MenuComponent';
import './Practice.css'; 

const PracticeWriting = () => {
  let navigate = useNavigate();
  const location = useLocation();     
  
  /********** CONVERSATION **********/ 
  const [conversationIndex, setConversationIndex] = useState(0);  
  const [currentIndex, setCurrentIndex] = useState(0); // which pair of application-user 
  const [visiblePairs, setVisiblePairs] = useState([conversations[conversationIndex].pairs[currentIndex]]); // Start with the first pair visible
  const [showDescription, setShowDescription] = useState(true);  
  const [answersIndex, setAnswersIndex] = useState(null);     // which answer
  const [answersIdx, setAnswersIdx] = useState(null);     // which answer
  const [answer, setAnswer] = useState('');
  const [situationDescription, setSituationDescription] = useState(conversations[conversationIndex].description);
  
  useEffect(() => {
    setSituationDescription(conversations[conversationIndex].description);
    setCurrentIndex(0);    
    setVisiblePairs([conversations[conversationIndex].pairs[0]]); 
    setAnswersIndex(null);
    setAnswersIdx(null);
    setTranslationIndex(null);
    setTranslationText('');
    setUserTranslationIndex(null);
    setUserTranslationIdx(null);
    setUserTranslationText(''); 
  }, [conversationIndex]);

  const changeSituation = () => {
    const nextConversationIndex = conversationIndex + 1 < conversations.length ? conversationIndex + 1 : 0;
    setConversationIndex(nextConversationIndex);
  }
  const toggleShowAnswer = (index,idx) => {
    if (answersIndex === index && answersIdx === idx) {
      setAnswersIndex(null);
      setAnswersIdx(null);
    } else{
      setAnswersIndex(index);   
      setAnswersIdx(idx);  
    }           
  };
  
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const handleAnswerChange = (newAnswer) => {
    setAnswer(newAnswer);
  };

  const handleAnswerSubmit = () => {
    if (!userInteracted) { setUserInteracted(true); }
    findBestMatch();
    // nextConversationPair();
  };
  
  const nextConversationPair = () => {
    const nextIndex = currentIndex + 1; 
    if (nextIndex < conversations[conversationIndex].pairs.length) {
      setVisiblePairs(prevVisiblePairs => [...prevVisiblePairs, conversations[conversationIndex].pairs[nextIndex]]);    
      setCurrentIndex(nextIndex);
    } else {
      navigate('/grammar-report', { state: { conversationIndex } });  //only show report for current conversation   
    }
  };

  //auto scroll to the latest conversation pair
  const messagesEndRef = useRef(null);
  useEffect(() => {
    const scrollToEnd = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Use setTimeout to ensure the SweetAlert has rendered before scrolling
    const timeoutId = setTimeout(scrollToEnd, 100);

    return () => clearTimeout(timeoutId);
  }, [visiblePairs]);
 
   
  
  /********** TRANSLATION **********/ 
  const [translationText, setTranslationText] = useState('');
  const [translationIndex, setTranslationIndex] = useState(null); 
  const [userTranslationText, setUserTranslationText] = useState('');
  const [userTranslationIndex, setUserTranslationIndex] = useState(null); 
  const [userTranslationIdx, setUserTranslationIdx] = useState(null); 

  const translateApplication = (text, index) => {
    if (translationIndex === index) {
      setTranslationIndex(null);
      setTranslationText(''); // Clear translation text
    } else {
      setTranslationIndex(index);
      setTranslationText(text); // Set translation text
    }
  };  

  const translateSuggestAnswer = (text, index, idx) => {
    if(userTranslationIndex === index && userTranslationIdx === idx){
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    } else{
      setUserTranslationIndex(index);
      setUserTranslationIdx(idx);
      setUserTranslationText(text);
    }
  };

  /********** MATCH USER ANSWER **********/ 
  const [highestMatch, setHighestMatch] = useState([0]);
  const {updateConversationIndex, updateUserAnswer, updateMatchPercentage, updateMatchSuggestResponse } = useUserWritingAnswers();
  const [bestMatchSentence, setBestMatchSentence] = useState(['']);
  const [userInteracted, setUserInteracted] = useState(false);
    
  function findBestMatch() {
    const sentencesA = visiblePairs[currentIndex].suggestResponses;
    let newHighestMatches = [...highestMatch]; // Clone the current state to modify

    sentencesA.forEach(sentence => {
        let matchPercentage = getMatchPercentage(answer, sentence.fr);        
              
        if (currentIndex >= newHighestMatches.length) {
          newHighestMatches.push(0);  // Extend the highestMatch array if new answer          
        }

        // Update the highest match if the new match is better
        if (matchPercentage > newHighestMatches[currentIndex]) {
          newHighestMatches[currentIndex] = matchPercentage;
          setBestMatchSentence(sentence.fr);
        }             
    });    
    
    // Update the state only once after all calculations are done
    setHighestMatch(newHighestMatches);
  }  

  useEffect(() => { 
    if(userInteracted){
      updateConversationIndex(conversationIndex);
      updateUserAnswer(conversationIndex, currentIndex, answer); 
      updateMatchPercentage(conversationIndex, currentIndex, highestMatch[currentIndex]); 
      updateMatchSuggestResponse(conversationIndex, currentIndex, bestMatchSentence);
      let value = highestMatch[currentIndex];
      let message;
      let iconUrl;

      switch (true) {
        case (value < 10):
          message = "Don't give up!";
          iconUrl = 'Icons/dontgiveup.svg'; 
          break;
        case (value >= 10 && value < 20):
          message = "You can do it";
          iconUrl = 'Icons/youcandoit.svg';   
          break;
        case (value >= 20 && value < 30):
          message = "Keep practicing";
          iconUrl = 'Icons/keeppracticing.svg';
          break;
        case (value >= 30 && value < 40):
          message = "Keep going";
          iconUrl = 'Icons/keepgoing.svg';  
          break;
        case (value >= 40 && value < 50):
          message = "Not bad";
          iconUrl = 'Icons/notbad.svg'; 
          break;
        case (value >= 50 && value < 60):
          message = "Good job!";
          iconUrl = 'Icons/goodjob.svg';
          break;
        case (value >= 60 && value < 70):
          message = "Bravo!";
          iconUrl = 'Icons/bravo.svg';
          break;
        case (value >= 70 && value < 75):
          message = "Super!";
          iconUrl = 'Icons/super.svg';
          break;
        case (value >= 75 && value < 80):
          message = "Excellent!";
          iconUrl = 'Icons/excellent.svg';
          break;
        case (value >= 80 && value < 90):
          message = "Wow!";
          iconUrl = 'Icons/wow.svg';
          break;
        case (value >= 90):
          message = "What a pro!";
          iconUrl = 'Icons/pro.svg';
          break;
        default:
          message = "Value is out of range";
      }     

      if (currentIndex + 1 < conversations[conversationIndex].pairs.length) {
        showSweetAlert(message, 'practice-message', iconUrl);
      } 
      setAnswer(''); 
      nextConversationPair();   
    }           
  }, [ highestMatch]); // This effect runs only when `userAnswers` changes  

  /********** POPUP INSTRUCTION **********/
  const descriptionRef = useRef(null);
  const optionRef = useRef(null);
  const speakRef = useRef(null);

  const steps = [
    { ref: descriptionRef, label: "Collapsible description: ", text: "Use this description to complete your task" },
    { ref: optionRef, label: "Options: ", text: "Use these as suggested answers. Click * see answers" },
    { ref: speakRef, label: "Speak: ", text: "Switch to speaking" },
  ];

  /********** INTERFACE **********/ 
  return (
    <div className="practice-page">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button">Back</button>
        <h4 style={{marginRight:'0px'}}>Practice Writing</h4>
        <div className="top-icons-container">       
          <div><MenuComponent menuType="type2"/></div>
          <FlagComponent/>
          <button ref={speakRef} onClick={() => navigate('/practice-speaking')} className="back-button">Speak</button>
        </div>    
      </div> 
      <HelpComponent steps={steps}/>
      <div className="practice-body">      
      <div className={`scenario-description ${showDescription ? 'expanded' : 'collapsed'}`}>        
        {showDescription && <div >
          <span style={{fontWeight:'700'}}>Use this information to complete this task. Options 1-3 are suggested answers. Click </span>
          <span style={{fontWeight:'700', textDecoration: 'underline', color: 'red', cursor: 'pointer'}} onClick={changeSituation}>here</span> 
          <span style={{fontWeight:'700'}}> to select another situation.</span>
          <br/>
          <span>{situationDescription.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>
          </div>
        }   
        {!showDescription && <div>
          <br/>
          </div>
        }        
        <button ref={descriptionRef} className="collapse-button" onClick={toggleDescription} >{showDescription ? '▲' : '▼'}</button>
      </div>
      <div className="conversation-dialog-container">
      {visiblePairs.map((pair, index) => (        
      <div  className="conversation-dialog">
        <div className="application-answer-container">
          <div className="application-answer">
              <span style={{fontSize:'clamp(10px, 2.5vw, 16px)'}}>{pair.application.fr}&nbsp;</span>
              <button style={{scale: '0.8', borderColor:'#062e5f', backgroundColor:'#C1EEE6', boxShadow: '0 2px 0  rgba(6, 49, 95, 1)'}} className="translation-button" 
              onClick={() => translateApplication(pair.application.en, index)}>   {/*TODO: hardcode to first response; future development: get a random application response */}
                  <img src="Icons/translation.svg" alt="Translation" />
              </button>  
            <br/>
            {translationIndex === index && (
              <span style={{fontSize:'clamp(10px, 2.5vw, 16px)'}}>{translationText}</span>
            )}      
          </div>  
        </div>
        <div className="user-answers-container">      
          <div  className="user-answers">
          <span style={{marginLeft:'2px', color:'#FFC145'}}><strong>Hint: </strong>{pair.hint}</span>
            {pair.suggestResponses.map((response, idx) => (
              <div key={idx} >
                { idx < 3 && (<>
                <div className="user-answer">
                  <span ref={optionRef} >{`Option ${idx + 1}: `}</span>  
                  <button style={{scale: '0.8', backgroundColor:'#C1EEE6', margin:'0px', marginRight:'3px'}} className="listen-button" onClick={() => translateSuggestAnswer(response.en, index, idx)}>
                    <img src="Icons/translation.svg" alt="Translation" />
                  </button>                
                  <span className="suggest-answer" onClick={() => toggleShowAnswer(index, idx)}>
                    {(answersIndex === index && answersIdx === idx) ? response.fr : '******************'}   {/*sampleAnswer.replace(/./g, '*') */}
                  </span>   
                  {userTranslationIndex === index && userTranslationIdx === idx && (
                    <span ><br/>{userTranslationText}</span>
                  )} 
                  </div>                              
                </>)}                   
              </div>
            ))}            
          </div>
        </div>
      </div>
      ))}
      <div ref={messagesEndRef} />
      </div>
      
      <AnswerInput 
        answer={answer}
        onAnswerChange={handleAnswerChange}
        onAnswerSubmit={handleAnswerSubmit}
      />  
      </div>  
    </div>
  );
};

export default PracticeWriting;