import React, { createContext, useState, useContext } from 'react';

  const FavoriteVocab = createContext({
    favorites: new Set(),
    toggleFavorite: () => {}
  });
  
  export const useFavorites = () => useContext(FavoriteVocab);
  
  export const FavoritesVocabProvider = ({ children }) => {
    const [favorites, setFavorites] = useState(new Set());
  
    const toggleFavorite = (word) => {
        setFavorites(prevFavorites => {
            const updatedFavorites = new Set(prevFavorites);
            if (prevFavorites.has(word)) {
                updatedFavorites.delete(word);
            } else {
                updatedFavorites.add(word);
            }
            return updatedFavorites;
        });
    };
  
    return (
        <FavoriteVocab.Provider value={{ favorites, toggleFavorite }}>
            {children}
        </FavoriteVocab.Provider>
    );
  };