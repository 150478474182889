import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginScreen from './MainPages/Home'; 
import ThemeSelection from './Scenario/ThemeSelection';
import ScenarioSelection from './Scenario/ScenarioSelection';
import SpeakingSentences from './Learn/SpeakingSentences'; 
import ListeningSentences from './Learn/ListeningSentences';  
import QuebecSentences from './Learn/QuebecSentences'; 
import SummaryPage from './Learn/SummaryPage';  
import PracticeSpeaking from './Practice/PracticeSpeaking';  
import PracticeWriting from './Practice/PracticeWriting';  
import CompletionPage from './Completion/CompletionPage';  
import PronunciationReport from './Completion/PronunciationReport';  
import GrammarReport from './Completion/GrammarReport'; 
import ReviseConversation from './Completion/ReviseConversation';  
import Study from './MainPages/Study';  
import Profile from './MainPages/Profile'; 
import MyVocabularies from './MainPages/MyVocabularies';  
import ScrollToTop  from './Reusables/ScrollToTop';
import './App.css';

const App = () => {
  return (
    <div style={{ background: '#FFFFFF' }}>        
    <Router>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/theme-selection" element={<ThemeSelection />} />
        <Route path="/scenario-selection" element={<ScenarioSelection />} />
        <Route path="/speaking-sentences" element={<SpeakingSentences />} />
        <Route path="/listening-sentences" element={<ListeningSentences />} />
        <Route path="/quebec-sentences" element={<QuebecSentences />} />
        <Route path="/summary-page" element={<SummaryPage />} />
        <Route path="/practice-speaking" element={<PracticeSpeaking />} />
        <Route path="/practice-writing" element={<PracticeWriting />} />
        <Route path="/completion-page" element={<CompletionPage />} />
        <Route path="/pronunciation-report" element={<PronunciationReport />} />   
        <Route path="/grammar-report" element={<GrammarReport />} />  
        <Route path="/revise-conversation" element={<ReviseConversation />} />    
        <Route path="/study" element={<Study />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/my-vocabulary" element={<MyVocabularies />} /> 
      </Routes>
    </Router>    
    </div>
  );
};

export default App;