import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSpeakingAnswers } from '../Practice/UserSpeakingAnswers';
import { useUserWritingAnswers } from '../Practice/UserWritingAnswers';
import { showSweetAlert } from '../Reusables/Utilities';
import Swal from 'sweetalert2';
import './CompletionPage.css'; 

const CompletionPage = () => {
  let navigate = useNavigate();
  const {userSpeakAnswers, theme, topic, scenario} = useUserSpeakingAnswers();
  const {userWriteAnswers} = useUserWritingAnswers();

  return (
    <div className="completion-page">
      <div className="completion-page-content">
        <p style={{fontWeight:'700', fontSize:'clamp(14px, 4vw, 20px)', color: '#FFC145'}}>Bravo! You just finished</p>
        <ul className="completion-details">
          <li><b>Theme:</b> Travel</li>             
          <li><b>Topic:</b> Flight</li>
          <li><b>Scenario:</b> Flight Booking</li>
        </ul>

        <div className="report-buttons">
          <button onClick={() => navigate('/pronunciation-report')} className="report-button pronunciation">Pronunciation report</button>
          <button onClick={() => navigate('/grammar-report')} className="report-button grammar">Grammar <br/> report</button>
        </div>

        <div className="practice-options">
          <br/>
          <button onClick={() => navigate('/practice-speaking')} className="option-button"><img src="Icons/refresh.svg" alt="Speak" />Practice again</button>                  
          <button className="option-button" onClick={() => navigate('/revise-conversation')}><img src="Icons/book.svg" alt="Revise" />Revise this conversation</button> 
          <button onClick={() => navigate('/scenario-selection')} className="option-button"><img src="Icons/another.svg" alt="Scenario" />Choose another scenario</button>
          
          {/* <button onClick={() => handlePracticeOtherSkills('Writing')} className="option-button"><img src="Icons/pencil.svg" alt="Write" />Practice writing</button>
          <button onClick={() => handlePracticeOtherSkills('Listening')} className="option-button"><img src="Icons/ear.svg" alt="Listen" />Practice listening</button>
          <button onClick={() => handlePracticeOtherSkills('Reading')} className="option-button"><img src="Icons/read.svg" alt="Read" />Practice reading</button>                   
          <button className="option-button" onClick={() => navigate('/print-conversation')}><img src="Icons/printer.svg" alt="Print" />Print this conversation</button>
          <button className="option-button"><img src="Icons/share.svg" alt="Share" />Share this conversation</button> */}
          
        </div>
      </div> 
      <div className="icons-container">       
        <div className="icon home-icon" onClick={() => navigate('/')}><img src="Icons/home.svg" alt="Home" /></div>
        <div className="icon study-icon" onClick={() => navigate('/study')}><img src="Icons/study.svg" alt="Study" /></div>
        <div className="icon person-icon" onClick={() => navigate('/profile')}><img src="Icons/account-settings.svg" alt="Listen" /></div>
      </div>    
    </div>
  );
};

export default CompletionPage;