import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

export const showSweetAlert = (message, name, iconUrl) => {
    return Swal.fire({
        title: message,
        imageUrl: iconUrl,
        showConfirmButton: false,
        timer: 1000, // Auto-close after 1s
        customClass: {
          popup: name
        }
    });
  };

export const removePunctuation = (text) => {
  var punctuation = /[.,?!'"']/g;   //g means remove . , ? ! ' " in whole sentence
  var newText = text.replace(punctuation, '');
  return newText;
};

export const highlightMatches = (sentenceB, sentencesA) => {
  if (!sentencesA || !sentenceB) return;

  const wordsA = sentencesA.split(/\s+/);             // Does not convert word to lowercase here to preserve original sentence
    //split words based on one or more whitespace and enter
  const wordsB = new Set(removePunctuation(sentenceB.toLowerCase()).replace(/-/g, ' ').split(/\s+/)); 
  // Create a Set from sentenceB -> lowercase -> remove punctuation -> replace - by a space (when user speaks fast, words may be connected) -> split

  return wordsA.map((word, index) => {
      return (
          <span key={index} style={{ color: acceptableAnswer(wordsB, word.toLowerCase()) ? '#00C667' : '#F40000' }}>
              {word + ' '}
          </span>
      );
  });
};

const acceptableAnswer = (textB, textA) => {
  if (/^[,\.?!'"]$/.test(textA)){
    return true;    //if textA is a standalone ? ! , . ' "
  } else if (textB.has(removePunctuation(textA))) {
    return true;
  } else { 
    const textAnew = removePunctuation(textA).replace(/-/g, ' ').split(/\s+/); //handle case textA has - but textB does not i.e. pouvez-vous     
    return textAnew.every(word => textB.has(word));
  }
}

export const getMatchPercentage = (textB, textA) => {
  const wordsB = removePunctuation(textB.toLowerCase()).replace(/-/g, ' ').split(/\s+/);
  const wordsA = removePunctuation(textA.toLowerCase()).replace(/-/g, ' ').split(/\s+/);
  const matches = wordsB.filter(word => wordsA.includes(word));
  return (matches.length / wordsA.length) * 100;
}

export const calculateAverageMatch = (userAnswers) => {  //userAnswers here correspond to one conversation
  if (!userAnswers || !Array.isArray(userAnswers.pairs)) {
    return 0; // Return 0 if userAnswers is not valid or doesn't have a pairs array
  }

  let totalMatch = 0;
  let numPairs = 0;

  userAnswers.pairs.forEach(pair => {
      totalMatch += pair.matchPercentage; // Assume matchPercentage is a number
      numPairs++;
  });

  if (numPairs > 0) {
      return (totalMatch / numPairs);
  }
}

export const InstructionBubble = ({ message, name }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //sessionStorage.removeItem(name);    //to manually clear a session
    // Check if the bubble has been shown in this session
    if (!sessionStorage.getItem(name)) {
      setIsVisible(true); // If not shown, make it visible
      sessionStorage.setItem(name, 'true'); // Set a flag in sessionStorage

      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 2000); // The bubble will disappear after 2 seconds

      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, []);

  if (!isVisible) return null;

  return (
    <div className={name}>
      {message}
    </div>
  );
};