import React, { useState, useRef, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sentencesForSpeaking, sentencesForListening, sentencesForQuebec} from './Sentences'; 
import { InstructionBubble } from '../Reusables/Utilities';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import FlagComponent from '../Reusables/FlagComponent';
import HelpComponent from '../Reusables/HelpComponent';
import MenuComponent from '../Reusables/MenuComponent';

const SummaryPage = () => {
  const navigate = useNavigate();  
  const location = useLocation();
  const { sentenceIndex, fromSpeaking, fromListening, fromQuebec } = location.state || { sentenceIndex: 0 , fromSpeaking: false, fromListening : false, fromQuebec: false}; 
  const [showSpeaking, setShowSpeaking] = useState(() => {
    const saved = localStorage.getItem('showSpeaking');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [showListening, setShowListening] = useState(() => {
    const saved = localStorage.getItem('showListening');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [showQuebec, setShowQuebec] = useState(() => {
    const saved = localStorage.getItem('showQuebec');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [showAudioControl, setShowAudioControl] = useState(() => {
    const saved = localStorage.getItem('showAudioControl');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const {isPlaying, isPlayingSlower, playPause, stopAudio, slowerPause, currentAudioFile} = usePlayAudio(); 
  const [audioFile, setAudioFile] = useState('');

  const toggleSpeaking = () => {
    setShowSpeaking(prevState => {
      const newState = !prevState;
      localStorage.setItem('showSpeaking', JSON.stringify(newState));
      return newState;
    });
  };
  const toggleListening = () => {
    setShowListening(prevState => {
      const newState = !prevState;
      localStorage.setItem('showListening', JSON.stringify(newState));
      return newState;
    });
  };
  const toggleQuebec = () => {
    setShowQuebec(prevState => {
      const newState = !prevState;
      localStorage.setItem('showQuebec', JSON.stringify(newState));
      return newState;
    });
  };const toggleAudioControl = () => {
    setShowAudioControl(prevState => {
      const newState = !prevState;
      localStorage.setItem('showAudioControl', JSON.stringify(newState));
      return newState;
    });
  };

  // Helper functions to navigate to the specific sentence
  const navigateToSpeakingSentence = (index) => {
    stopAudio();
    navigate('/speaking-sentences', { state: { index, fromSummary : true } });
  };

  const navigateToListeningSentence = (index) => {
    stopAudio();
    navigate('/listening-sentences', { state: { index, fromSummary : true } });
  };

  const navigateToQuebecSentence = (index) => {
    stopAudio();
    navigate('/quebec-sentences', { state: { index, fromSummary : true } });
  };

  const goToPractice = () => {
    stopAudio();
    navigate('/practice-speaking');
  };

  const goBack = () => {
    stopAudio();
    if (fromSpeaking){
      navigate('/speaking-sentences' , { state: { index: sentenceIndex }});          
    } else if (fromListening){
      navigate('/listening-sentences' , { state: { index: sentenceIndex }});          
    } else if (fromQuebec){
      navigate('/quebec-sentences' , { state: { index: sentenceIndex }});     
    } else {
      navigate('/quebec-sentences' , { state: { index: sentencesForQuebec.length - 1 }});          
    }    
  };
  const [isActive, setIsActive] = useState(true);

  /********** TRANSLATION **********/ 
  const [showTranslationSpeak, setShowTranslationSpeak] = useState(false);
  const [showTranslationListen, setShowTranslationListen] = useState(false);
  const [showTranslationQuebec, setShowTranslationQuebec] = useState(false);
  const [translationText, setTranslationText] = useState('');
  const [translationSpeakIndex, setTranslationSpeakIndex] = useState(null);
  const [translationListenIndex, setTranslationListenIndex] = useState(null);
  const [translationQuebecIndex, setTranslationQuebecIndex] = useState(null);
  
  const translateSpeak = (index, text) => {
    if (showTranslationSpeak && translationSpeakIndex === index) {
      setShowTranslationSpeak(false);
      setTranslationText(''); // Clear translation text
    } else {
      setShowTranslationSpeak(true);
      setShowTranslationListen(false);
      setShowTranslationQuebec(false);
      setTranslationText(text); // Set translation text
      setTranslationSpeakIndex(index);
    }
  };

  const translateListen = (index, text) => {
    if (showTranslationListen && translationListenIndex === index) {
      setShowTranslationListen(false);
      setTranslationText(''); // Clear translation text
    } else {
      setShowTranslationListen(true);
      setShowTranslationSpeak(false);
      setShowTranslationQuebec(false);
      setTranslationText(text); // Set translation text
      setTranslationListenIndex(index);
    }
  };

  const translateQuebec = (index, text) => {
    if (showTranslationQuebec && translationQuebecIndex === index) {
      setShowTranslationQuebec(false);
      setTranslationText(''); // Clear translation text
    } else {
      setShowTranslationQuebec(true);
      setShowTranslationSpeak(false);
      setShowTranslationListen(false);
      setTranslationText(text); // Set translation text
      setTranslationQuebecIndex(index);
    }
  };
  
  const setSentence = (audioFile, type, index, text) => {
    setAudioFile(audioFile);
    switch (true) {
      case (type === 'speak'):
        translateSpeak(index, text);
        break;
      case (type === 'listen'):
        translateListen(index, text);
        break;
      case (type === 'quebec'):
        translateQuebec(index, text);
        break;
      default:
        console.log('error: invalid sentence type');
    }
  }
  
  /********** POPUP INSTRUCTION **********/
  const toggleRef = useRef(null);
  const practiceRef = useRef(null);

  const steps = [
    { ref: toggleRef, label: "Expandable bar: ", text: "Click this bar to see all the sentences. Click each sentence for more" },
    { ref: practiceRef, label: "Practice: ", text: "Practice speaking & writing when you are ready!"}
  ];
  
  return (
    <div className="summary-page">    
      <div className="top-row">
        <button onClick={goBack} className="back-button">Back</button>
        <h4>List of sentences for this scenario</h4>
        <div className="top-icons-container">       
          <div><MenuComponent menuType="type1"/></div>
          <FlagComponent/>  
        </div> 
      </div>
      <HelpComponent steps={steps} /> 
      <div className="summary-body">
      
      <button  onClick={toggleSpeaking} className={`toggle-button ${showSpeaking ? 'expanded' : 'collapsed'}`}>
        <span ref={toggleRef} style={{fontWeight: '600', fontSize: 'clamp(15px, 4vw, 17px)'}}>Sentences for speaking</span>   
        <span >{showSpeaking ? '▲' : '▼'}</span>
      </button>               
      {showSpeaking && sentencesForSpeaking.map((entry, index) => (
        <React.Fragment key={index}>     
        <div className="sentence-with-controls">
        <div  className={`summary-sentence ${showTranslationSpeak &&  translationSpeakIndex === index ? 'active' : ''} `}>            
          <p  style={{fontSize:'clamp(10px, 4vw, 16px)'}} onClick={() => setSentence(entry.audio, 'speak', index, entry.en)}>{entry.fr}</p>          
          {showTranslationSpeak && translationSpeakIndex === index && (
            <div>
              
              <div className="controls" style={{paddingLeft:'5px'}}>
                <button  className="listen-button" onClick={() => playPause(entry.audio)}>
                  <img src={isPlaying && currentAudioFile === entry.audio ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
                </button>
                <button className="slowdown-button" onClick={() => slowerPause(entry.audio)}>
                  <img src={isPlayingSlower && currentAudioFile === entry.audio ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
                </button>
                {isPlayingSlower && currentAudioFile === entry.audio && <PlaybackRateControl />}     
                
              </div>                      
              <p style={{color: '#D5668A', fontSize: 'clamp(10px, 5vw, 16px)'}}>{translationText}</p>
            </div>
          )}     
        </div>  
        {showTranslationSpeak && translationSpeakIndex === index && (    
          <span style={{cursor: 'pointer', color:'#FFC145', fontWeight: '500', fontSize: 'clamp(11px, 5.5vw, 17px)', textDecoration: 'underline' }} onClick={() => navigateToSpeakingSentence(index)}>Study</span>
        )}                   
        </div>             
        </React.Fragment>
      ))}      

      <button onClick={toggleListening} className={`toggle-button ${showListening ? 'expanded' : 'collapsed'}`}>
        <span style={{fontWeight: '600', fontSize: 'clamp(15px, 4vw, 17px)'}}>Sentences for listening</span>
        <span>{showListening ? '▲' : '▼'}</span>
      </button>
      {showListening && sentencesForListening.map((entry, index) => (
        <React.Fragment key={index}>
        <div className="sentence-with-controls">
        <div  className="summary-sentence">            
          <p  style={{fontSize:'clamp(10px, 4vw, 16px)'}} onClick={() => setSentence(entry.audio, 'listen', index, entry.en)}>{entry.fr}</p>
          {showTranslationListen && translationListenIndex === index && (
            <div>
              <div className="controls" style={{paddingLeft:'5px'}}>
                <button  className="listen-button" onClick={() => playPause(entry.audio)}>
                  <img src={isPlaying && currentAudioFile === entry.audio ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
                </button>
                <button className="slowdown-button" onClick={() => slowerPause(entry.audio)}>
                  <img src={isPlayingSlower && currentAudioFile === entry.audio ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
                </button>
                {isPlayingSlower && currentAudioFile === entry.audio && <PlaybackRateControl />}     
              </div>                      
              <p style={{color: '#D5668A', fontSize: 'clamp(10px, 5vw, 16px)'}}>{translationText}</p>
            </div>
          )}     
        </div>   
        {showTranslationListen && translationListenIndex === index && (    
          <span style={{cursor: 'pointer', color:'#FFC145', fontWeight: '500', fontSize: 'clamp(11px, 5.5vw, 17px)', textDecoration: 'underline' }} onClick={() => navigateToListeningSentence(index)}>Study</span>    
        )}          
        </div>        
        </React.Fragment>
      ))}

      <button onClick={toggleQuebec} className={`toggle-button ${showQuebec ? 'expanded' : 'collapsed'}`}>
        <span style={{fontWeight: '600', fontSize: 'clamp(15px, 4vw, 17px)'}}>Quebec expressions</span>
        <span>{showQuebec ? '▲' : '▼'}</span>
      </button>      
      {showQuebec && sentencesForQuebec.map((entry, index) => (
        <React.Fragment key={index}>
        <div className="sentence-with-controls">
        <div  className="summary-sentence">            
          <p  style={{fontSize:'clamp(10px, 4vw, 16px)'}} onClick={() => setSentence(entry.QCaudio, 'quebec', index, entry.en)}>{entry.qc}</p>
          {showTranslationQuebec && translationQuebecIndex === index && (
            <div>
              <div className="controls" style={{paddingLeft:'5px'}}>
                <button  className="listen-button" onClick={() => playPause(entry.QCaudio)}>
                  <img src={isPlaying && currentAudioFile === entry.QCaudio ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
                </button>
                <button className="slowdown-button" onClick={() => slowerPause(entry.QCaudio)}>
                  <img src={isPlayingSlower && currentAudioFile === entry.QCaudio ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
                </button>
                {isPlayingSlower && currentAudioFile === entry.QCaudio && <PlaybackRateControl />}     
              </div>                      
              <p style={{color: '#D5668A', fontSize: 'clamp(10px, 5vw, 16px)'}}>{translationText}</p>
            </div>
          )}     
        </div> 
        {showTranslationQuebec && translationQuebecIndex === index && (    
          <span style={{cursor: 'pointer', color:'#FFC145', fontWeight: '500', fontSize: 'clamp(11px, 5.5vw, 17px)', textDecoration: 'underline' }} onClick={() => navigateToQuebecSentence(index)}>Study</span>   
        )}  
        </div>        
        </React.Fragment>
      ))}
      </div>
      <button ref={practiceRef} className="summary-practice" onClick={goToPractice}><b>PRACTICE</b></button>
    
    </div>
  );
};

export default SummaryPage;