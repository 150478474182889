
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useFavorites } from './FavoriteVocab';
import { vocabularies } from '../Learn/Vocabularies';
import './MyVocabularies.css'; 

const MyVocabularies = () => {
  let navigate = useNavigate();
  const { favorites, toggleFavorite } = useFavorites();
  const removeFavorites = (word) => {
    toggleFavorite(word);
  };

  // Convert favorites Set to an array of vocabulary objects
  const favoriteVocabularies = Array.from(favorites).map(favoriteWord => {
    return vocabularies[favoriteWord];
  }).filter(vocab => {
    const exists = vocab !== undefined;
    if (!exists) {
      console.log('A favorite word was not found in the vocabularies list.');
    }
    return exists;
  });
  
 const speak = (audioFile) => {
    const audio = new Audio(audioFile);
    
    audio.oncanplay = () => {      
      audio.play().catch(error => console.error("Error playing the audio", error));
    };
    audio.onerror = (event) => {
        console.error("Error occurred when trying to load the audio.", event);
    };
  }
  
  return(
    <div className="my-vocabularies-page">      
        <div className="top-row">
            <button onClick={() => navigate(-1)} className="back-button">Back</button>
            <h2>My Vocabularies</h2>
        </div>
        <div className="my-vocabularies-list">
          {favoriteVocabularies.map((vocab, index) => (
            <div>
              <div key={index} className="vocab-item">             
                <span className="french-word">{vocab.french}</span>
                <span className="ipa">{vocab.ipa}</span>
                <button className="audio-button" onClick={() => speak(vocab.audio)}>
                  <img src="Icons/listenDictionary.svg" alt="Listen" />
                </button>
                <button className="delete-button" onClick={() => removeFavorites(vocab.french)}>
                  <img src="Icons/trash.svg" alt="Delete" /> 
                </button>                  
                <br/>          
              [{vocab.wordType}]
              <br/>              
              {vocab.english}
              </div> 
            </div>
          ))}
        </div> 

        <div className="icons-container">       
        <div className="icon home-icon" onClick={() => navigate('/')}><img src="Icons/home.svg" alt="Home" /></div>
        <div className="icon study-icon" onClick={() => navigate('/study')}><img src="Icons/study.svg" alt="Study" /></div>
        <div className="icon person-icon" onClick={() => navigate('/profile')}><img src="Icons/account-settings.svg" alt="Listen" /></div>
        </div>
    </div>
  )
};

export default MyVocabularies;