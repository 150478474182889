import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { sentencesForSpeaking, sentencesForListening } from './Sentences'; 
import { useFavorites } from '../MainPages/FavoriteVocab';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import FlagComponent from '../Reusables/FlagComponent';
import MenuComponent from '../Reusables/MenuComponent';

const ListeningSentences = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  const { index, fromSummary } = location.state || { index: 0, fromSummary : false }; 
  const [cameFromSummary, setCameFromSummary] = useState(fromSummary);  // handling go to next sentence in listening
  const [current, setCurrent] = useState(0);
  const currentVocabulary = sentencesForListening[current]?.vocabulary || [];
  const { favorites, toggleFavorite } = useFavorites();
  const {isPlaying, isPlayingSlower, playPause, stopAudio, play, slowerPause, currentAudioFile} = usePlayAudio(); 

  useEffect(() => {
    // Update the current state if the index changes due to navigation
    setCurrent(index);
  }, [index]);
  
  const goBack = () => {
    if (fromSummary && cameFromSummary) {
      navigate('/summary-page'); 
    } else if (current === 0) {
      navigate('/speaking-sentences' , { state: { index: sentencesForSpeaking.length - 1 }});      
    } else {
      setCurrent(current - 1);
      stopAudio();
      showTranslation = false;    //reset translation button
      translate('');
    }
  };

  const goToNext = () => {
    if (current < sentencesForListening.length - 1) {
      setCameFromSummary(false);
      setCurrent(current + 1);
      stopAudio();
      showTranslation = false;
      translate('');
    } else {
      navigate('/quebec-sentences');
    }
  };

  const goToSummary = () => {
    navigate('/summary-page', { state: { sentenceIndex: current, fromSpeaking : true }});
    stopAudio();
  }

  /********** TRANSLATION **********/ 
  let showTranslation = false;
  const translate = (translationText) => {
    showTranslation = !showTranslation;
    showTranslation ? document.getElementById('translation').innerText = translationText : document.getElementById('translation').innerText = '';
  }  

  return (
    <div className="sentence-practice">
      <div className="top-row">
        <button onClick={goBack} className="back-button">Back</button>
        <h4>Listen to this sentence</h4>
        <div className="top-icons-container">       
          <div><MenuComponent menuType="type1"/></div>
          <FlagComponent/>          
        </div> 
      </div>   
      <div className="practice-body">
      <div>        
        </div> 
        <div className="sentence-with-controls">
          <span className="french-sentence"><b>{sentencesForListening[current]?.fr}</b></span>
          <div className="controls">
            <button className="listen-button" onClick={() => playPause(sentencesForListening[current]?.audio, true, 1)}>
            <img src={isPlaying ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying ? "Pause" : "Listen"} /> 
            </button>
            <button className="slowdown-button" onClick={() => slowerPause(sentencesForListening[current]?.audio)}>
            <img src={isPlayingSlower ? "Icons/pause.svg" : "Icons/turtle.svg"} alt={isPlayingSlower ? "Pause" : "Slow"} /> 
            </button>
            {isPlayingSlower && currentAudioFile === sentencesForListening[current]?.audio && <PlaybackRateControl />}
            <button className="translation-button" onClick={() => translate(sentencesForListening[current]?.en)}>
              <img src="Icons/translation.svg" alt="Translation" />
            </button>
          </div>
        </div>
        <div id="translation" style={{paddingLeft:'30px'}}></div>
        <div className="vocabulary-list">
          {currentVocabulary.map((vocab, index) => (
            <div>
              <div key={index} className="vocab-item">             
                <span className="french-word">{vocab.french}</span>
                <span className="ipa">{vocab.ipa}</span>
                <button className="audio-button" onClick={() => play(vocab.audio)}>
                  <img src="Icons/listenDictionary.svg" alt="Listen" />
                </button>
                <button className="favorite-button" onClick={() => toggleFavorite(vocab.french)}>
                  {favorites.has(vocab.french) ? <img src="Icons/red-heart.svg" alt="FilledHeart" /> : <img src="Icons/heart.svg" alt="UnfilledHeart"/>}
                </button>                  
                <br/>              
                <span>[{vocab.wordType}]</span>
                <br/>              
                <span>{vocab.english}</span>
                <br/>
                {vocab.wordType === 'Verb' && vocab.tense !== '' && (
                  <div className="conjugation">                  
                    <span><b>{vocab.tense}</b></span>
                    <br/>
                    <span>{vocab.tenseConjugation.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>        
      </div>
      <div className="navigation-buttons">
        <button className="summary-button" onClick={goToSummary}>Summary</button>
        <button className="next-button" onClick={goToNext}>Next</button>     
      </div>      
    </div>
  );
};

export default ListeningSentences;