import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; 

const Home = () => {
  let navigate = useNavigate();

  const handleLogin = () => {
    navigate('/theme-selection');
  };

  return (
    <div className="login-container">
      <div className="logo">        
        <img src="logo-header-center.svg" alt="Logo" />
      </div>
      
        <button className="login-button" onClick={handleLogin}>START</button>
         {/* <div className="disclaimer"></div> */}
      
    </div>
  );
};

export default Home;