import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FavoritesVocabProvider } from './MainPages/FavoriteVocab';
import { UserSpeakingAnswersProvider } from './Practice/UserSpeakingAnswers'
import { UserWritingAnswersProvider } from './Practice/UserWritingAnswers'
import { PlayAudioProvider } from './Reusables/PlayAudio';
import { RecordUserProvider } from './Reusables/RecordUser';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FavoritesVocabProvider> 
    <UserSpeakingAnswersProvider>
    <UserWritingAnswersProvider>
    <PlayAudioProvider>
    <RecordUserProvider>
      <App />
    </RecordUserProvider>
    </PlayAudioProvider>     
    </UserWritingAnswersProvider>
    </UserSpeakingAnswersProvider>
    </FavoritesVocabProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
